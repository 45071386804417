/* ==== Google font ==== */
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic|Montserrat:700|Merriweather:400italic');

/* ==== Style ==== */

body {
    width: 100%;
    height: 100%;
    font-family: 'Lato',sans-serif;
    /* font-weight: 100; */
    font-size: 18px;
    color: #666;
    background-color: #fff;
}

html {
    width: 100%;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 30px;
    font-family: 'Lato',sans-serif;
    /* font-weight: 700; */
    letter-spacing: 2px;
}

p {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 1.6em;
}

p.lead {
    font-weight: 600;
}

a {
    color:#e8715f;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    color:#e39b00;
}

.light {
    font-weight: 400;
}

.navbar {
    margin-bottom: 0;
    //background-color: rgba(rgb(255,255,255),0.6);
    background-color: #ffffff;
    border-bottom: 1px solid #eeeeee;
}

.navbar-brand {
    font-weight: 700;
}
.navbar-brand {
    height: 64px;
    padding: 10px 10px;
    font-size: 18px;
    line-height: 1em;
}

.navbar-brand h1{
    color: #fff;
    font-size: 20px;
    line-height: 60px;
}

.navbar-brand:focus {
    outline: 0;
}

.nav.navbar-nav {
    //background-color: rgba(255,255,255,0.2);
    //background-color: rgba(0,0,0,0.6);
}

.navbar-custom.top-nav-collapse .nav.navbar-nav {
    background-color: rgba(rgb(0,0,0),0.6);
}


.navbar-custom ul.nav li a {
    font-size: 18px;
    letter-spacing: 2px;
    color: #48c0ba;
    font-weight: 400;
    text-transform: lowercase;
    line-height: 35px;
}
.navbar-custom ul.nav li a i{
    color: #f58765 !important;
}

.navbar-custom.top-nav-collapse ul.nav li a {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    //color: #5baea9;
    color: #fff;
}

.navbar-custom ul.nav ul.dropdown-menu {
    background-color: rgba(rgb(255,255,255),0.9);
    border-radius: 0;
}

.navbar-custom ul.nav ul.dropdown-menu li {
    border-bottom: 1px solid #eeeeee;

}

.navbar-custom ul.nav ul.dropdown-menu li:last-child{
    border-bottom: none;
}

.navbar-custom ul.nav ul.dropdown-menu li a {
    color: #e8715f;
    //color: #fff;
    padding: 10px 20px;
}

.navbar-custom ul.nav ul.dropdown-menu li a:hover {
    background-color: rgba(rgb(200,200,200),0.2);
}

.navbar-custom.top-nav-collapse ul.nav ul.dropdown-menu li a {
    color: #666;
    //color: #fff;
}

.navbar-custom .nav li a {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li.active {
    outline: 0;
    background-color: rgba(rgb(227,155,0),0.1);
    //background-color: rgba(rgb(255,255,255),0.2);
}

.navbar-toggle {
    padding: 4px 6px;
    font-size: 18px;
    color: #fff;
    line-height: 35px;
}

.navbar-toggle:focus,
.navbar-toggle:active {
    outline: 0;
}







.color-primary {
    color: #48c0ba;    
}
.color-secondary {
    color: #f58765;
}
.bg-primary {
    background-color: #48c0ba;
    color: #ffffff;
}
.bg-secondary {
    background-color: #f58765;
    color: #ffffff;
}
.bg-paper {
    background-image: url(../img/bg/paper_tile.jpg);
    background-repeat: repeat;
}
.bg-paper-grey {
    /*background-image: url(../img/bg/paper_tile_grey.jpg);
    background-repeat: repeat;*/
    background-color: #fff;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.bg-paper-primary {
    background-image: url(../img/bg/paper_tile2.jpg);
    background-repeat: repeat;
    color: #ffffff;
}
.bg-paper-secondary {
    background-image: url(../img/bg/paper_tile3.jpg);
    background-repeat: repeat;
    color: #ffffff;
}


/* ===========================
--- Intro
============================ */

#section-intro{
    background: url("../img/banner/banner.jpg") no-repeat top center;
    background-size: cover;
}
#section-intro-msn {
    background: url("../img/banner/msn_banner.jpg") no-repeat center center;
    background-size: cover;
}
#section-intro-mlb {
    background: url("../img/banner/mlb_banner.jpg") no-repeat center center;
    background-size: cover;
}


/* ===========================
--- Footer
============================ */

footer {
    text-align: center;
    background-image: url(../img/bg/paper_tile2.jpg);
    background-repeat: repeat;
    color: #a3d8d4;
    line-height: 2em;
}

footer a {
    color: #a3d8d4;
    text-decoration: none !important;
}
footer a:hover {
    color: #666 !important;
}

footer .social a{
    padding:16px;
}

footer .sponsor img{
    padding:16px;

    opacity: 0.6;
    filter: alpha(opacity=60); /* For IE8 and earlier */
}

hr {
    border-color: #a3d8d4;
}
